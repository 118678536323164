import { News } from '@b4online/api-common'
import { CardContent, Link, Stack, Typography } from '@mui/material'
import moment from 'moment'
import NextLink from 'next/link'
import React from 'react'

import { Icon } from '../Icon/Icon'
import { TextIconLabel } from './components/TextIconLabel'
import TextMaxLine from './components/TextMaxLine'

interface NewsPostCardProps {
  index: number
  newsPost: News.News
}

export const NewsPostCard: React.FC<NewsPostCardProps> = ({ index, newsPost }) => {
  return (
    <CardContent
      sx={{
        pt: 4.5,
        width: 1,
      }}>
      <Typography
        gutterBottom
        variant={'caption'}
        component={'div'}
        sx={{
          color: 'text.disabled',
        }}>
        {moment(new Date(newsPost.timestamp)).format('DD.MM.yyy')}
      </Typography>

      <NextLink href={'/news/' + transformNewsHeadline(newsPost.headline)} passHref>
        <Link color={'inherit'}>
          <TextMaxLine variant={'h5'} sx={{ textDecoration: 'none !important' }} line={2} persistent>
            {newsPost.headline}
          </TextMaxLine>
        </Link>
      </NextLink>

      <Stack
        flexWrap={'wrap'}
        direction={'row'}
        gap={1}
        justifyContent={'flex-end'}
        sx={{
          mt: 3,
          color: 'text.disabled',
        }}>
        {newsPost.author && (
          <TextIconLabel
            key={index + newsPost.author}
            icon={<Icon icon={'ant-design:user-outlined'} sx={{ width: 16, height: 16, mr: 0.5 }} />}
            value={newsPost.author}
            sx={{ typography: 'caption', ml: index === 0 ? 0 : 1.5 }}
          />
        )}
        {newsPost.attachments && newsPost.attachments.length > 0 && (
          <TextIconLabel
            key={index + 'attachments'}
            icon={<Icon icon={'carbon:document-attachment'} sx={{ width: 16, height: 16, mr: 0.5 }} />}
            value={newsPost.attachments.length + ' ' + (newsPost.attachments.length === 1 ? 'Anhang' : 'Anhänge')}
            sx={{ typography: 'caption', ml: index === 0 ? 0 : 1.5 }}
          />
        )}
      </Stack>
    </CardContent>
  )
}

export const transformNewsHeadline = (headline: string): string => {
  return headline
    .toLowerCase()
    .split(' - ')
    .join('-')
    .split('   ')
    .join('  ')
    .split('  ')
    .join(' ')
    .split(' ')
    .join('-')
    .split('ä')
    .join('ae')
    .split('ö')
    .join('oe')
    .split('ü')
    .join('ue')
    .split('!')
    .join('')
    .split('.')
    .join('')
    .split('/')
    .join('')
    .split(':')
    .join('')
    .split(';')
    .join('')
}
