import { Link, LinkProps, Typography, TypographyProps } from '@mui/material'
import { Variant } from '@mui/material/styles/createTypography'
import { forwardRef, ReactNode } from 'react'
import React from 'react'

// utils
import getFontValue from '../utils/getFontValue'

// ----------------------------------------------------------------------

type IProps = TypographyProps & LinkProps

interface Props extends IProps {
  line?: number
  asLink?: boolean
  persistent?: boolean
  children: ReactNode
  variant?: Variant
}

const TextMaxLine = forwardRef<HTMLAnchorElement, Props>(({ asLink, variant = 'body1', line = 2, persistent = false, children, sx, ...other }, ref) => {
  const { lineHeight } = getFontValue(variant)

  const style = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: line,
    WebkitBoxOrient: 'vertical',
    ...(persistent && {
      height: lineHeight * line,
    }),
    ...sx,
  } as const

  if (asLink) {
    return (
      <Link color={'inherit'} ref={ref} variant={variant} sx={{ ...style }} {...other}>
        {children}
      </Link>
    )
  }

  return (
    <Typography ref={ref} variant={variant} sx={{ ...style }} {...other}>
      {children}
    </Typography>
  )
})

export default TextMaxLine
