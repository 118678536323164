import { News } from '@b4online/api-common'
import { Box, Button, Card, Grid, Stack, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { GetServerSideProps, NextPage } from 'next'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import * as React from 'react'

import { api } from '../api'
import { BannerVideo, CenterText, FullImageContainer, ImageButton, SponsorBar } from '../components'
import { NewsPostCard, transformNewsHeadline } from '../components/News/NewsPostCard'
import { sources } from '../utils'
import { NewsImage } from './news'

type IndexProps = {
  newsResponse: News.GetNewsResponse
}

const Index: NextPage<IndexProps> = ({ newsResponse }) => {
  const theme = useTheme()
  const router = useRouter()

  return (
    <React.Fragment>
      <BannerVideo src={sources.cdn('/videos/tatort-hawaii-intro2-large.mp4')} sx={{ height: '40vh' }} />
      <SponsorBar />
      <Box mt={7} mb={9} mx={2}>
        <Stack mt={6} mb={6} direction={'column'} alignItems={'center'} textAlign={'center'}>
          <Typography variant={'h3'} sx={{ mb: 2, textTransform: 'uppercase' }}>
            Entdeck&apos; Tatort-Hawaii!
          </Typography>
          <Typography variant={'body2'} sx={{ maxWidth: 900 }}>
            Bei uns in Stein (Kiel) kannst Du Windsurfen, Wingfoilen, Kitesurfen oder Standup Paddeln erlernen. Der Strand erleichtert den Einstieg in diese Sportarten mit seinem
            großen Stehrevier. Unabhängig vom Alter und mitgebrachter Erfahrung freuen wir uns auf alle, die dem Wassersport ein Stück näher kommen möchten! Mit Kaffee, Kuchen,
            Pizza, Flammkuchen, Cocktails oder einem frisch gezapften Bier der Kieler Brauerei Lille versorgen wir auch täglich. So lässt sich der Blick auf den Strand genießen.
            Wir freuen uns auf Deinen Besuch!
          </Typography>
        </Stack>
        <Box sx={{ maxWidth: 1200 }} mx={'auto'} pb={8}>
          <Typography variant={'h4'} align={'center'} sx={{ mb: 2 }}>
            Aktuelles
          </Typography>
          <Box sx={{ overflowX: 'auto' }}>
            <Grid container spacing={3} sx={{ width: 'calc(100vw * 3 - 32px)', maxWidth: 1224 }}>
              {newsResponse.entries.map((post, index) => (
                <Grid key={post._id} item xs={4}>
                  <NextLink href={'/news/' + transformNewsHeadline(post.headline)} passHref>
                    <Card
                      component={'a'}
                      sx={{
                        display: 'block',
                        height: 1,
                        overflow: 'hidden',
                        '> img': {
                          transition: 'transform 0.3s',
                        },
                        '&:hover': {
                          '> img': {
                            transform: 'scale(1.1)',
                          },
                        },
                      }}>
                      <NewsImage alt={'cover'} src={post.bannerImage ? post.bannerImage : 'https://zone-assets-api.vercel.app/assets/img_placeholder.svg'} />
                      <NewsPostCard newsPost={post} index={index} />
                    </Card>
                  </NextLink>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
        <Box display={'grid'} gridTemplateColumns={{ xs: '1fr', md: '1fr 1fr' }} gap={6} sx={{ maxWidth: 1200 }} mx={'auto'}>
          <ImageButton imageSrc={'https://images.b4online.de/tatort-hawaii/images/cards/surfschule.png?w=800'} label={'Surfschule'} onClick={() => router.push('/surfschule')} />
          <ImageButton imageSrc={'https://images.b4online.de/tatort-hawaii/images/cards/gastronomie.png?w=800'} label={'Gastronomie'} onClick={() => router.push('/gastronomie')} />
          <ImageButton imageSrc={'https://images.b4online.de/tatort-hawaii/images/cards/surfcamp.png?w=800'} label={'Surfcamp'} onClick={() => router.push('/surfcamp')} />
          <ImageButton imageSrc={'https://images.b4online.de/tatort-hawaii/images/cards/eventagentur.png?w=800'} label={'Eventagentur'} onClick={() => router.push('/events')} />
          <ImageButton imageSrc={'https://images.b4online.de/tatort-hawaii/images/cards/konzerte.png?w=800'} label={'Konzerte'} onClick={() => router.push('/konzerte')} />
          <ImageButton imageSrc={'https://images.b4online.de/tatort-hawaii/images/cards/shop.png?w=800'} label={'Shop'} onClick={() => router.push('/shop')} />
          <ImageButton imageSrc={'https://images.b4online.de/tatort-hawaii/images/cards/hochzeiten.png?w=800'} label={'Hochzeiten'} onClick={() => router.push('/hochzeiten')} />
          <ImageButton
            imageSrc={'https://images.b4online.de/tatort-hawaii/images/cards/strandkörbe.png?w=800'}
            label={'Strandkörbe'}
            onClick={() => router.push('/buchen/strandkorb')}
          />
        </Box>
      </Box>
      <FullImageContainer imageSrc={sources.cdn('/images/backgrounds/tatort-hawaii-background-1-resize.jpg')} fixed />
      <CenterText contrastColor={theme.palette.background.default} color={theme.palette.background.contrast}>
        <Stack direction={'column'} alignItems={'center'} sx={{ maxWidth: 1200, mx: 'auto' }} gap={2} py={8}>
          <Typography variant={'h3'}>Über Uns</Typography>
          <Typography variant={'body2'}>
            Seit 2002 sind wir im stetigen Wandel, angefangen mit unserer kleinen Surfschule. So spürt ihr noch heute in jedem Detail unsere Liebe zum Wassersport! <br />
            Mittlerweile haben wir neben unserer lizenzierten Wassersportstation ein Testcenter mit Shop. Hier könnt ihr neustes Kite-, Wing-, Windsurf und SUP-Material testen. Wir
            bringen euch mit unserer Expertise aufs Board. Ob Kitesurfen, Windsurfen, Wingfoilen und das Stand Up Paddling, bei uns lernt ihr das nötige Know How. <br />
            Der Steiner Strand ist wunderschön und der stehtiefe Wasserbereich perfekt, um sicher Stand Up Paddling, Wingfoilen, Kite- oder Windsurfen zu erlernen.
          </Typography>
          <Button onClick={() => router.push('/about')} sx={{ textTransform: 'uppercase' }}>
            Mehr dazu
          </Button>
        </Stack>
      </CenterText>
      <FullImageContainer imageSrc={sources.cdn('/images/backgrounds/tatort-hawaii-background-3-resize.jpg')} fixed />
    </React.Fragment>
  )
}

export default Index

export const getServerSideProps: GetServerSideProps = async () => {
  const response = await api.news.getNews()

  if (response.result === 'error') throw response

  const copiedResponse = {
    ...response,
    entries: response.entries
      .sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime())
      .map(entry => {
        return {
          ...entry,
          timestamp: entry.timestamp.toISOString(),
        }
      })
      .slice(0, 3),
  }

  return {
    props: {
      newsResponse: copiedResponse,
    },
  }
}
