import { News } from '@b4online/api-common'
import { Box, Card, Grid, styled, Typography } from '@mui/material'
import { GetServerSideProps, NextPage } from 'next'
import NextLink from 'next/link'
import React from 'react'

import { api } from '../../api'
import { BannerImage, Container, SponsorBar } from '../../components'
import { NewsPostCard, transformNewsHeadline } from '../../components/News/NewsPostCard'

type NewsType = {
  newsResponse: News.GetNewsResponse
}

export const NewsPosts: NextPage<NewsType> = ({ newsResponse }) => {
  return (
    <Box justifyContent={'center'} alignItems={'center'}>
      <BannerImage src={'/static/images/banners/news.png'} />
      <SponsorBar />
      <Container py={5}>
        <Box pb={6}>
          <Typography variant={'h3'} align={'center'} textTransform={'uppercase'}>
            News
          </Typography>
          <Typography variant={'body2'} align={'center'} pb={3}>
            Hier erfährst du alles, was aktuell bei uns los ist und findest Infos zu Neuigkeiten und Veranstaltungen. Wir freuen uns auf dich!
          </Typography>
        </Box>
        <Grid container spacing={8}>
          {newsResponse.entries.map((post, index) => (
            <Grid key={post._id} item xs={12} sm={6}>
              <NextLink href={'/news/' + transformNewsHeadline(post.headline)} passHref>
                <Card component={'a'} sx={{ display: 'block', height: 1 }}>
                  <NewsImage alt={'cover'} src={post.bannerImage ? post.bannerImage : 'https://zone-assets-api.vercel.app/assets/img_placeholder.svg'} />
                  <NewsPostCard newsPost={post} index={index} />
                </Card>
              </NextLink>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  )
}

export const NewsImage = styled('img')(() => ({
  width: '100%',
  height: 200,
  objectFit: 'cover',
}))

export default NewsPosts

export const getServerSideProps: GetServerSideProps = async () => {
  const response = await api.news.getNews()

  if (response.result === 'error') throw response

  const copiedResponse = {
    ...response,
    entries: response.entries
      .sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime())
      .map(entry => {
        return {
          ...entry,
          timestamp: entry.timestamp.toISOString(),
        }
      }),
  }

  return {
    props: {
      newsResponse: copiedResponse,
    },
  }
}
